import * as React from 'react';
import { graphql } from 'gatsby';
import { Box } from '@chakra-ui/react';
import queryString from 'query-string';
import SeoComponent from '../components/SeoComponent';

/* IMPORT APP CONTEXT */
import { AppContext } from '../context';

/* SECTIONS */
import Hero from '../sections/Hero';
import { ProductsListFilter } from '../sections/ProductsListFilter';
import DropdownEffectHome from '../sections/DropdownEffectHome';

const isBrowser = typeof window !== "undefined";

function ShopAllPage(props) {
  const { data, location, pageContext } = props;
  
  const pageData = pageContext.pageData.data.sanityPage;
	const appContext = React.useContext(AppContext);
  const heroData = pageContext.pageData.data.sanityPage.sections.find(item => item._type === 'hero');
  const seoImg = pageData.seoImage?.image || heroData?.heroImg?.bgImage;

  const [pageMounted, setPageMounted] = React.useState(false);

  /* REFS */
  const productsListRef = React.useRef(null);
  const pageRef = React.useRef(null);

  /* FILTER */
  const qsObject = queryString.parse(location.search, { arrayFormat: 'comma' });

  Object.keys(qsObject).map((key) => {
    if (typeof qsObject[key] === 'string') {
      qsObject[key] = [qsObject[key]];
    }
  });

  const heroRef = React.useRef(null);

  const [allStates, setAllStates] = React.useState(null);

	/* PAGE LOADED */
	React.useEffect(() => {
		/* SCROLL TO TOP */
		appContext.pageTopRef.current.scrollIntoView();
    
    const statesList = data.allSanityProduct.group.map(item => {
      const stateValue = item.fieldValue.trim().toLowerCase().replace('-', ' ');

      return {
        name: stateValue, 
        slug:{current: stateValue},
      };
    });

    const orderedStates = statesList.sort(function (a, b) {
      if (a.slug.current > b.slug.current) {
        return 1;
      }
      if (a.slug.current < b.slug.current) {
        return -1;
      }
      
      return 0;
    });
    
    setAllStates(orderedStates);
    setPageMounted(true);
    
    return () => {
      setPageMounted(false);

    };
	}, []);

  return (
		<>
			<SeoComponent
				title={pageData.seoTitle}
				description={pageData.seoDescription}
        imageUrl={seoImg?.asset?.url}
			/>

			<Box 
        ref={pageRef}
        maxW="100%"
      >
				{/* HERO */}
				<Hero
					subheader={heroData.accentHeader.headerText}
					header={heroData.mainHeader.headerText}
					heroBgImg={heroData.heroImg}
          heroRef={heroRef}
				/>

        {pageMounted && allStates && (
          <>
            {/* PRODUCTS LIST AND FILTER */}
            <ProductsListFilter 
              refToUse={productsListRef}
              location={location} 
              filter={qsObject}
              slashQuery={null}
              hasSubcategories={false}
              topBarFilter={pageContext.shopTopBarItems}
              isCollectionPage={null}
              currentPage={pageContext.heroHeader}
              productsToFilter={data.allSanityProduct.nodes}
              allStates={allStates}
              productsBaseRoute={pageContext.productsBaseRoute}
            />

            {/* DROPDOWN */}
            <DropdownEffectHome onShopPage={true}	pageTopRef={productsListRef} />
          </>
        )}

			</Box>
		</>
  );
}

export const query = graphql`
  query {
    allSanityProduct(sort: {
     order: [ASC, ASC], 
     fields: [subcategory___slug___current, name]}) {
      nodes {
        isPublic
        name
        seoTitle
        seoDescription
        seoImage {
          image {
            asset {
              url
            }
          }
        }
        slug {
          current
        }
        collectionImage {
          asset {
            gatsbyImageData(placeholder: NONE, width: 276, height: 276)
          }
        }
        category {
          name
          ctaRoute {
            route
          }
          slug {
            current
          }
        }
        subcategory {
          name
          ctaRoute {
            route
          }
          slug {
            current
          }
        }
        infoPerState {
          isDefault
          useDefaultDetails
          useDefaultCompoundInfo
          useDefaultAdditionalInfo
          _rawDetails
          _rawAdditionalInfo
          warning
          state {
            name
            abbrevation
            slug {
              current
            }
          }
          productImage {
            asset {
              gatsbyImageData(width: 668, height: 535, fit: MAX, placeholder: NONE)
            }
          }
          details {
            children {
              _type
              marks
              text
            }
            _type
            list
            style
          }
          additionalInfo {
            _type
            style
            list
            children {
              text
              marks
            }
          }
          compoundInformation {
            compound {
              name
              slug {
                current
              }
            }
            compoundPerPKG
            compoundPerPiece
          }
        }
        effects {
          name
          slug {
            current
          }
        }
        lineages {
          name
          slug {
            current
          }
        }
        nutriInfo
        ingredients
        unitsPerPackage
        durationImage {
          asset {
            gatsbyImageData(width: 172, height: 111, placeholder: NONE)
          }
        }
        typeOfStore {
          name
          slug {
            current
          }
        }
      }
      group(field: infoPerState___state___name) {
        fieldValue
      }
    }
  }
`;

export default ShopAllPage;
